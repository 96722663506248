import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

interface FoerderHoehe {
  title: string | null;
  richDescription: any;
}

interface FoerderHoeheStichpunktProps {
  stichpunkt: FoerderHoehe;
}

export const FoerderHoeheStichpunkt: FC<FoerderHoeheStichpunktProps> = ({
  stichpunkt,
}) => (
  <Stack
    spacing={2}
    sx={{
      p: 4,
      border: 0.5,
      borderColor: `primary.main`,
      bgcolor: `primary.light`,
      borderRadius: `8px`,
    }}
  >
    <Typography>{stichpunkt?.title}</Typography>
    <Typography>
      {stichpunkt.richDescription &&
        renderRichText(stichpunkt.richDescription as RenderRichTextData<any>)}
    </Typography>
  </Stack>
);
