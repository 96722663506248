import { createTheme } from '@mui/material';
import '@fontsource/gurajada';
const headingFontFamily = {
  fontFamily: [`Gurajada`, `Arial`, `Roboto`].join(`,`),
};
const theme = createTheme({
  typography: {
    fontFamily: [`Arial`, `Gurajada`, `Roboto`].join(`,`),
    ...Object.fromEntries(
      Array.from({ length: 6 }, (_, i) => [`h${i + 1}`, headingFontFamily]),
    ),
  },
  palette: {
    primary: { main: `#214475`, light: `rgba(60, 100, 177, 0.06)` },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // [`& fieldset`]: {
          //   borderRadius: 0,
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // borderRadius: 0,
          textTransform: `none`,
        },
      },
      defaultProps: {
        variant: `contained`,
      },
    },
  },
});

export default theme;
