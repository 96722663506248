import { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';

export const Footer: FC = () => {
  return (
    <div>
      <Box
        sx={{
          height: (theme) => theme.spacing(8),
          bgcolor: `#4F4F4F`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        <Link
          sx={{ color: `#C3CBCD`, mr: 1 }}
          component={GatsbyLink}
          to={`/impressum`}
        >
          Impressum
        </Link>
        <Link
          sx={{ color: `#C3CBCD`, ml: 1 }}
          component={GatsbyLink}
          to={`/datenschutz`}
        >
          Datenschutz
        </Link>
      </Box>
      <Box
        sx={{
          height: (theme) => theme.spacing(5),
          bgcolor: `#373F41`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        <Typography variant="body2" sx={{ color: `#C3CBCD` }}>
          © DevConsultBerlin. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
};
