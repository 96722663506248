import {
  CssBaseline,
  Stack,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { Welcome } from '@/components/Welcome';
import { ZusammenFassung } from '@/components/zusammenfassung/ZusammenFassung';
import { FoerderHoehe } from '@/components/foerderHoehe/FörderHöhe';
import { FoerderBerechtigte } from '@/components/foerderBerechtigte/FoerderBerechtigte';
import { UnsereHilfe } from '@/components/usereHilfe/UnsereHilfe';
import { PageEnd } from '@/components/pageEnd/PageEnd';
import { Footer } from '@/components/Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import { SEO } from '@/components/Seo';
import theme from '@/gatsby-theme-material-ui-top-layout/theme';
import { Faq } from '@/components/Faq';
import ResponsiveAppBar from '@/components/AppBar';
import Toolbar from '@mui/material/Toolbar';

export default function Home() {
  const showParallax = useMediaQuery(theme.breakpoints.only(`xl`));

  return (
    <SEO>
      <ThemeProvider theme={theme}>
        <ParallaxProvider>
          <CssBaseline />
          <main>
            <ResponsiveAppBar />
            <Toolbar />
            <Stack spacing={0}>
              <Welcome parallax={showParallax} />
              <ZusammenFassung parallax={showParallax} />
              <FoerderHoehe parallax={showParallax} />
              <FoerderBerechtigte parallax={showParallax} />
              <UnsereHilfe parallax={showParallax} />
              <PageEnd parallax={showParallax} />
              <Faq />
              <Footer />
            </Stack>
          </main>
        </ParallaxProvider>
      </ThemeProvider>
    </SEO>
  );
}
