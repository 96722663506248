import { Field, FieldProps } from 'formik';
import { InputDescriptor } from '@/components/checker/components/QuestionsForm';
import * as yup from 'yup';
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { InfoPopup } from '@/components/checker/components/InfoPopup';

export interface OptionQuestionInputProps {
  fieldName: string;
  options: { key: string; label: string; info?: JSX.Element | string }[];
  spacing?: number;
  submit?: () => void;
}

export function OptionQuestionInput({
  fieldName,
  options,
  spacing,
  submit,
}: OptionQuestionInputProps) {
  return (
    <Field
      name={fieldName}
      render={({ meta, form }: FieldProps<boolean | undefined>) => {
        return (
          <RadioGroup name={fieldName} value={meta.value}>
            <Grid container spacing={spacing}>
              {options.map((option) => {
                return (
                  <Grid item xs={12} key={option.key}>
                    <Box display="flex">
                      <FormControlLabel
                        name={fieldName}
                        value={option.key}
                        control={<Radio />}
                        sx={{ flex: 1 }}
                        label={
                          <Box
                            display="flex"
                            sx={{ alignItems: `center`, maxWidth: 460 }}
                          >
                            {option.label}
                          </Box>
                        }
                        onChange={(_, checked) => {
                          if (checked) {
                            form.setFieldValue(fieldName, option.key);
                            if (submit) {
                              submit();
                            }
                          }
                        }}
                      />
                      {option.info && (
                        <Box
                          sx={{
                            marginLeft: `2rem`,
                            display: `flex`,
                            alignItems: `center`,
                          }}
                        >
                          <InfoPopup content={option.info} />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        );
      }}
    />
  );
}

export function createOptionInput<E extends string>(
  questionText: string,
  fieldName: string,
  options: { key: E; label: string; info?: JSX.Element | string }[],
  questionInfo?: JSX.Element | string,
  spacing?: number,
): InputDescriptor<E> {
  return {
    questionText,
    questionInfo,
    instantSubmitable: true,
    validator: yup
      .mixed()
      .oneOf(options.map((o) => o.key))
      .required(`Bitte wählen Sie eine Option`),
    inputElement: ({ submit }) => (
      <OptionQuestionInput
        submit={submit}
        spacing={spacing}
        fieldName={fieldName}
        options={options}
      />
    ),
  };
}
