import React from 'react';
import { Box, Typography } from '@mui/material';
import { Script } from 'gatsby';
import { DeMinimisQuestionData } from '@/components/checker/questions/DeMinimisQuestion';
import { Player } from '@lottiefiles/react-lottie-player';
// @ts-ignore
import checkAnimation from '@/components/checker/check-animation.json';

function CalendlyWidgetContainer({
  contact,
}: {
  contact: { name: string; email: string };
}) {
  return (
    <>
      <Script
        src="https://assets.calendly.com/assets/external/widget.js"
        async
        type="text/javascript"
        onLoad={() => {
          (window as any).Calendly.initInlineWidget({
            url: `https://calendly.com/devconsult-berlin/25-minute-meeting`,
            parentElement: document.getElementById(
              `calendly-embed-widget-container`,
            ),
            prefill: {
              email: contact.email,
              name: contact.name,
            },
            utm: {},
          });
        }}
      />
      <div style={{ width: `100%`, marginTop: `15px` }}>
        <div
          /* className="calendly-inline-widget"
    data-url="https://calendly.com/d/ydb-vxy-r8q/erstgesprach-mit-devconsult-zu-digitalpramie" */
          id="calendly-embed-widget-container"
          style={{
            minWidth: `320px`,
            height: `630px`,
          }}
        />
      </div>
    </>
  );
}

export function SendedSuccessfullyPage({
  contact,
  data,
}: {
  contact: { name: string; email: string };
  data: DeMinimisQuestionData;
}) {
  // TODO maybe connect resulting event
  /* React.useEffect(() => {
    const listener = (e: any) => {
      if (
        e.origin === `https://calendly.com` &&
        e.data.event &&
        e.data.event.indexOf(`calendly.`) === 0
      ) {
        console.log(`calendly event`, e);
      }
    };

    window.addEventListener(`message`, listener);
    return () => window.removeEventListener(`message`, listener);
  }); */
  React.useEffect(() => {
    const el = document.getElementById(`email-send-title`);
    if (el) {
      el.scrollIntoView({
        behavior: `smooth`,
        block: `start`,
      });
    }
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center">
        <Player
          autoplay
          keepLastFrame
          style={{ height: `2.1875rem`, width: `2.1875rem` }}
          src={checkAnimation}
        />
        <Typography component="h2" variant="h3" sx={{ color: `#74cb1a` }}>
          Förderfähig
        </Typography>
      </Box>
      <Typography variant="body2" mb={3}>
        Geschafft! Ihr Vorhaben scheint grundsätzlich förderfähig zu sein.
        Weitere Details haben wir Ihnen per E-Mail zukommen lassen.
      </Typography>
      {data.item.type === `hardsoftware` && (
        <CalendlyWidgetContainer contact={contact} />
      )}
    </>
  );
}
