import { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const query = graphql`
  query FaqData {
    allContentfulFaq {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;

export const Faq: FC = () => {
  const data = useStaticQuery<Queries.FaqDataQuery>(query);

  return (
    <Box
      sx={{
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alginItems: `center`,
        py: 6,
      }}
    >
      <Typography variant="h3" sx={{ alignSelf: `center`, mb: 2 }}>
        FAQ
      </Typography>
      <Stack
        spacing={1}
        width={550}
        sx={{ alignSelf: `center`, maxWidth: `95%` }}
      >
        {data.allContentfulFaq.edges.map((data, i) => (
          <Accordion key={`faq-${i}`} variant="outlined">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="h5" variant="h6">
                {data.node.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {data.node.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>
  );
};
