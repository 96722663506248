import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { ProjectStartedQuestionData } from '@/components/checker/questions/ProjectStartedQuestion';
import { KMUCompanyBaseQuestionsData } from '@/components/checker/questions/KMUCompanyBaseQuestions';
import { CompanyEconomyQuestion } from '@/components/checker/questions/CompanyEconomyQuestion';

export type KMUCompanyExtendedQuestionsData = ProjectStartedQuestionData &
  KMUCompanyBaseQuestionsData & {
    company: {
      inBerlin: boolean;
      annualTurnoverInLimits: boolean;
      totalAnnualAssetsInLimits: boolean;
    };
  };
export function KMUCompanyExtendedQuestions({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<
  ProjectStartedQuestionData & KMUCompanyBaseQuestionsData
>) {
  return (
    <QuestionsForm
      inputMap={{
        inBerlin: createYesNoInput(
          `Ist Ihr Unternehmen mit einem Haupt- oder Betriebssitz gewerbesteuerpflichtig beim Berliner Finanzamt gemeldet?`,
          `inBerlin`,
        ),
        annualTurnoverInLimits: createYesNoInput(
          `Beträgt ihr Jahresumsatz maximal 50 mio €?`,
          `annualTurnoverInLimits`,
        ),
        totalAnnualAssetsInLimits: createYesNoInput(
          `Beträgt ihre Bilanzsumme maximal 43 mio €?`,
          `totalAnnualAssetsInLimits`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (!values.inBerlin) {
          reasons.push(
            `Nur Unternehmen mit einem gewerbesteuerpflichtig beim Berliner Finanzamt gemeldeten Haupt- oder Betriebssitz sind förderfähig.`,
          );
        }
        if (!values.annualTurnoverInLimits) {
          reasons.push(
            `Nur Unternehmen mit einem maximalen Jahresumsatz von 50 Mio € sind förderfähig.`,
          );
        }
        if (!values.totalAnnualAssetsInLimits) {
          reasons.push(
            `Nur Unternehmen mit einer Bilanzsumme von maximal 43 Mio € sind förderfähig.`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(CompanyEconomyQuestion, {
            ...data,
            company: {
              ...data.company,
              ...values,
            },
          });
        }
      }}
    />
  );
}
