import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { ItemExpenseQuestionsData } from '@/components/checker/questions/ItemExpenseQuestion';
import { SoloCompanyExtendedQuestions } from '@/components/checker/questions/SoloCompanyExtendedQuestions';
import { SoloCompanyBaseQuestionsData } from '@/components/checker/questions/SoloCompanyBaseQuestions';
import { KMUCompanyExtendedQuestions } from '@/components/checker/questions/KMUCompanyExtendedQuestions';
import { KMUCompanyBaseQuestionsData } from '@/components/checker/questions/KMUCompanyBaseQuestions';

export type ProjectStartedQuestionData = ItemExpenseQuestionsData & {
  item: {
    projectStarted: boolean;
  };
};

export function ProjectStartedQuestion({
  data,
  proceedWithUnsuitable,
  proceedWithQuestion,
}: QuestionComponentProps<ItemExpenseQuestionsData>) {
  return (
    <QuestionsForm
      inputMap={{
        projectStarted: createYesNoInput(
          `Haben Sie bereits mit dem Projekt begonnen?`,
          `projectStarted`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (values.projectStarted) {
          reasons.push(
            `Eine Förderung kann nur für zukünftige Projekte beantragt werden.`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          if (data.company.type === `solo`) {
            proceedWithQuestion(SoloCompanyExtendedQuestions, {
              ...(data as ItemExpenseQuestionsData &
                SoloCompanyBaseQuestionsData),
              item: {
                ...data.item,
                projectStarted: values.projectStarted,
              },
            });
          } else if (data.company.type === `kmu`) {
            proceedWithQuestion(KMUCompanyExtendedQuestions, {
              ...(data as ItemExpenseQuestionsData &
                KMUCompanyBaseQuestionsData),
              item: {
                ...data.item,
                projectStarted: values.projectStarted,
              },
            });
          }
        }
      }}
    />
  );
}
