import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { KMUCompanyExtendedQuestionsData } from '@/components/checker/questions/KMUCompanyExtendedQuestions';
import { SoloCompanyExtendedQuestionsData } from '@/components/checker/questions/SoloCompanyExtendedQuestions';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { DeMinimisQuestion } from '@/components/checker/questions/DeMinimisQuestion';

export type CompanyEconomyQuestionData = (
  | KMUCompanyExtendedQuestionsData
  | SoloCompanyExtendedQuestionsData
) & {
  company: {
    isPublic: boolean;
    inFinancialTrouble: boolean;
    lastSupport: boolean;
  };
};

export function CompanyEconomyQuestion({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<
  KMUCompanyExtendedQuestionsData | SoloCompanyExtendedQuestionsData
>) {
  return (
    <QuestionsForm
      inputMap={{
        isPublic: createYesNoInput(
          `Handelt es sich um ein öffentliches Unternehmen?`,
          `isPublic`,
        ),
        inFinancialTrouble: createYesNoInput(
          `Befindet sich Ihr Unternehmen in wirtschaftlichen Schwierigkeiten oder wurde ein Insolvenverfahren beantragt oder eröffnet?`,
          `inFinancialTrouble`,
        ),
        /*        stoppedBusiness: createYesNoInput(
          `Hat ihr Unternehmen die Geschäftstätigkeit oder Zahlungen eingestellt?`,
          `stoppedBusiness`,
        ),
        insolvency: createYesNoInput(
          `Wurde für das Vermögen Ihres Unternehmen ein Insolvenzverfahren beantragt oder eröffnet?`,
          `insolvency`,
        ),
        reclaims: createYesNoInput(
          `Ist Ihr Unternehmen einer Rückforderungsanordnung aufgrund eines früheren Beschlusses der Europäischen Kommission nicht nachgekommen?`,
          `reclaims`,
        ), */
        /*        otherSupport: createYesNoInput(
          `Wurde Ihrem Unternehmen bereits ein Antrag auf eine Digitalprämie Berlin bewilligt?`,
          `otherSupport`,
        ), */
        lastSupport: createYesNoInput(
          `Haben Sie in vergangenen Jahren bereits eine Förderung durch die Digitalprämie Berlin erhalten?`,
          `lastSupport`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];

        if (values.isPublic) {
          reasons.push(`Öffentliche Unternehmen sind nicht förderfähig.`);
        }
        if (values.inFinancialTrouble) {
          reasons.push(
            `Unternehmen in wirtschaftlichen Schwierigkeiten sind nicht förderfähig.`,
          );
        }
        /* if (values.stoppedBusiness) {
          reasons.push(
            `Unternehmen, die ihre Geschäftstätigkeit oder Zahlungen eingestellt haben, sind nicht förderfähig`,
          );
        }
        if (values.insolvency) {
          reasons.push(
            `Unternehmen, gegen deren Vermögen ein Insolvenzverfahren beantragt oder eröffnet wurde, sind nicht förderfähig`,
          );
        }
        if (values.reclaims) {
          reasons.push(
            `Unternehmen, die einer Rückforderungsanordnung aufgrund eines früheren Beschlusses der Europäischen Kommission nicht nachgekommen sind, sind nicht förderfähig`,
          );
        }
        if (values.otherSupport) {
          reasons.push(
            `Bei einer bereits bewilltigten Digitalprämie ist ein weiterer Antrag nicht möglich.`,
          );
        } */
        if (values.lastSupport) {
          reasons.push(
            `Eine Förderung ist nicht möglich, wenn bereits eine Förderung durch die Digitalprämie Berlin erhalten wurde`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(DeMinimisQuestion, {
            ...data,
            company: {
              ...data.company,
              ...values,
            },
          } as CompanyEconomyQuestionData);
        }
      }}
    />
  );
}
