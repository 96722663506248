import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createOptionInput } from '@/components/checker/components/OptionQuestionInput';
import { KMUCompanyBaseQuestionsData } from '@/components/checker/questions/KMUCompanyBaseQuestions';
import { SoloCompanyBaseQuestionsData } from '@/components/checker/questions/SoloCompanyBaseQuestions';
import { ItemExpenseQuestion } from '@/components/checker/questions/ItemExpenseQuestion';

export const ItemTypes = {
  hardsoftware: {
    name: `Digitale Arbeits-, Produktions- und Managementprozesse (erstmalige Anschaffung von Hard- oder Software)`,
    description: `Erstmalige Anschaffung von fortgeschrittener IT-Hardware und Software zur digitalen Transformation von Arbeits-, Produktions- und Managementprozessen.`,
  },
  provisionHardsoftware: {
    name: `Einführung oder Verbesserung der IT-Sicherheit (Hard- oder Software Investitionen)`,
    description: `Anschaffung von IT-Hardware und Software zur Einführung oder Verbesserung der betrieblichen IT-Sicherheit`,
  },
  consulting: {
    name: `Digitale Beratung und Qualifizierung`,
    description: `Digitalisierungsbezogene Beratungs- und Qualifizierungsmaßnahmen, die zur digitalen Transformation der Arbeits-, Produktions- und Managementprozesse sowie der Verbesserung der IT-Sicherheit beitragen`,
  },
  others: {
    name: `Sonstiges`,
    description: `Sonstiges`,
  },
};

export type ItemTypeQuestionsData = (
  | KMUCompanyBaseQuestionsData
  | SoloCompanyBaseQuestionsData
) & {
  item: { type: keyof typeof ItemTypes };
};

export function ItemTypeQuestion({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<
  KMUCompanyBaseQuestionsData | SoloCompanyBaseQuestionsData
>) {
  return (
    <QuestionsForm
      inputMap={{
        itemType: createOptionInput(
          `Was möchten Sie fördern lassen?`,
          `itemType`,
          Object.entries(ItemTypes).map(([k, v]) => ({
            key: k as keyof typeof ItemTypes,
            label: v.name,
            info: v.description,
          })),
          undefined,
          2,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (values.itemType === `others`) {
          reasons.push(`Die geplante Art der Ausgaben ist nicht förderfähig`);
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(ItemExpenseQuestion, {
            ...data,
            item: { type: values.itemType },
          });
        }
      }}
    />
  );
}
