import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface Stichpunkt {
  order: number | null;
  logo: {
    gatsbyImageData: Record<string, unknown> | null;
  } | null;
  description: string | null;
}

interface FoerderBerechtigteStichpunktProps {
  stichpunkt: Stichpunkt;
}

export const FoerderBerechtigteStichpunkt: FC<
  FoerderBerechtigteStichpunktProps
> = ({ stichpunkt }) => {
  const image = getImage(stichpunkt.logo!.gatsbyImageData);
  return (
    <Stack spacing={1}>
      <div>
        {image && <GatsbyImage image={image} alt={`stichpunkt logo`} />}
      </div>
      <Typography color="text.secondary" sx={{ maxWidth: 150 }}>
        {stichpunkt.description}
      </Typography>
    </Stack>
  );
};
