import { FC, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { CheckerModal } from '@/components/checker/CheckerModal';

const queryWithBigImage = graphql`
  query HeaderData {
    contentfulHeader(id: { eq: "ffb98cca-ae6c-5dfc-aa3d-568225108950" }) {
      title
      richDescription {
        raw
      }
      buttonText
      image {
        gatsbyImageData(
          height: 500
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export const Welcome: FC<{ parallax: boolean }> = ({ parallax }) => {
  const { contentfulHeader } =
    useStaticQuery<Queries.HeaderDataQuery>(queryWithBigImage);

  const image = getImage(contentfulHeader!.image);

  const [snackbarOpen, setSnackbarOpen] = useState(true);

  return (
    <Box
      sx={{
        bgcolor: `primary.light`,
        minHeight: `70vh`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      <CheckerModal>
        {(onClick) => (
          <Snackbar
            style={{ top: `50px` }}
            open={snackbarOpen}
            // onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ horizontal: `center`, vertical: `top` }}
            TransitionComponent={({ children }) => <>{children}</>}
          >
            <Alert
              severity="warning"
              onClose={() => setSnackbarOpen(false)}
              icon={`📣`}
            >
              <Typography component={`p`} variant="h5">
                Alle Mittel für die Digitalprämie 2023 sind ausgeschöpft!&nbsp;
                <Link
                  onClick={() => {
                    onClick();
                    setSnackbarOpen(false);
                  }}
                  sx={{ cursor: `pointer` }}
                >
                  Trotzdem Förderfähigkeit prüfen.
                </Link>
              </Typography>
            </Alert>
          </Snackbar>
        )}
      </CheckerModal>
      <Container>
        <Grid container spacing={parallax ? 14 : 4}>
          <Grid item xs={12} sm={12} md={6}>
            <Parallax speed={10} disabled={!parallax}>
              <Stack spacing={3}>
                <Typography
                  variant="h2"
                  component="h1"
                  // sx={{ lineHeight: { xs: `2.5rem`, md: undefined } }}
                >
                  {contentfulHeader?.title}
                </Typography>
                <Typography color="text.secondary">
                  {contentfulHeader?.richDescription &&
                    renderRichText(
                      contentfulHeader?.richDescription as RenderRichTextData<any>,
                    )}
                </Typography>
                <div>
                  <Typography
                    display="block"
                    variant="caption"
                    color="text.secondary"
                  >
                    Ergebnis in 3 Minuten
                  </Typography>
                  <CheckerModal>
                    {(onClick) => (
                      <Button
                        sx={{
                          maxWidth: 240,
                          alignSelf: parallax ? undefined : `center`,
                        }}
                        onClick={onClick}
                      >
                        {contentfulHeader?.buttonText ?? ``}
                      </Button>
                    )}
                  </CheckerModal>
                </div>
              </Stack>
            </Parallax>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Parallax speed={-10} disabled={!parallax}>
              <Box maxWidth={500} height={300}>
                {image && <GatsbyImage alt={`header-image`} image={image} />}
              </Box>
            </Parallax>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
