import { FC } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { CheckerModal } from '@/components/checker/CheckerModal';

const query = graphql`
  query PageEndData {
    contentfulPageEnd {
      title
      description
      buttonText
    }
  }
`;

export const PageEnd: FC<{ parallax: boolean }> = () => {
  const data = useStaticQuery<Queries.PageEndDataQuery>(query);

  return (
    <Box
      sx={{
        bgcolor: `primary.light`,
        // pt: parallax ? 10 : 3,
        // height: 300,
        py: 6,
      }}
    >
      <Container>
        <Stack spacing={4} alignItems="center">
          <Typography variant="h3">{data?.contentfulPageEnd?.title}</Typography>
          <Typography color="text.secondary">
            {data?.contentfulPageEnd?.description}
          </Typography>
          <CheckerModal>
            {(onClick) => (
              <Button onClick={onClick}>
                {data?.contentfulPageEnd?.buttonText}
              </Button>
            )}
          </CheckerModal>
        </Stack>
      </Container>
    </Box>
  );
};
