import { FC, PropsWithChildren } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { Box } from '@mui/material';

interface SideParallaxProps extends PropsWithChildren {
  position: 'left' | 'right';
  distanceToEdge?: number;
}

export const SideParallax: FC<SideParallaxProps> = ({
  position,
  distanceToEdge = 5,
  children,
}) => (
  <Box
    sx={{
      position: `absolute`,
      [position]: (theme) => theme.spacing(distanceToEdge),
    }}
  >
    <Parallax translateY={[20, 200]}>{children}</Parallax>
  </Box>
);
