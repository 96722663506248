import {
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { CompanyQuestion } from './questions/CompanyQuestion';
import { QuestionComponent } from '@/components/checker/components/QuestionComponent';
import { UnsuitableSlide } from '@/components/checker/UnsuitableSlide';
import { DeMinimisQuestionData } from '@/components/checker/questions/DeMinimisQuestion';
import { SuitableSlide } from '@/components/checker/SuitableSlide';
import CloseIcon from '@mui/icons-material/Close';

interface CheckerModalProps {
  children: (onClick: () => void) => JSX.Element;
}

export type SlideScreen =
  | {
      type: 'question';
      history: SlideScreen[];
      questionComponent: QuestionComponent<any>;
      data: any;
    }
  | { type: 'unsuitable'; reasons: string[]; previousScreen: SlideScreen }
  | { type: 'suitable'; data: DeMinimisQuestionData };

const initialSlide = {
  type: `question`,
  questionComponent: CompanyQuestion,
  history: [] as SlideScreen[],
  data: {},
} as const;

const QuestionHistoryContext = React.createContext({
  hasHistory: false,
  goBack: () => {},
});

export function useQuestionHistoryContext() {
  return React.useContext(QuestionHistoryContext);
}

const createMockData = (): DeMinimisQuestionData => ({
  company: {
    amountOfEmployees: `up-to-249`,
    annualTurnoverInLimits: false,
    foundedInTime: true,
    fulfillsDeMinimis: true,
    inBerlin: true,
    inFinancialTrouble: false,
    isPublic: false,
    lastSupport: false,
    totalAnnualAssetsInLimits: true,
    type: `kmu`,
  },
  item: {
    description: ``,
    expense: 1000,
    projectStarted: false,
    type: `consulting`,
  },
});

function InnerCheckerModal({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(`sm`));
  const [currentSlide, setCurrentSlide] =
    React.useState<SlideScreen>(initialSlide);
  React.useEffect(() => {
    if (window && process.env.NODE_ENV === `development`) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get(`_insertCalculatorMockData`) === `true`) {
        setCurrentSlide({
          type: `suitable`,
          data: createMockData(),
        });
      }
    }
  }, []);
  let slideElement: JSX.Element = <span>Should never happen</span>;
  if (currentSlide.type === `question`) {
    const QuestionFC = currentSlide.questionComponent;
    slideElement = (
      <QuestionFC
        data={currentSlide.data}
        proceedWithQuestion={(questionComponent, data) => {
          setCurrentSlide({
            type: `question`,
            history: [currentSlide, ...(currentSlide as any).history],
            questionComponent,
            data,
          });
        }}
        proceedWithUnsuitable={(reasons) =>
          setCurrentSlide({
            type: `unsuitable`,
            reasons,
            previousScreen: currentSlide,
          })
        }
        proceedWithSuitable={(data) =>
          setCurrentSlide({ type: `suitable`, data })
        }
      />
    );
  } else if (currentSlide.type === `unsuitable`) {
    slideElement = (
      <UnsuitableSlide
        revert={() => setCurrentSlide(currentSlide.previousScreen)}
        reasons={currentSlide.reasons}
      />
    );
  } else if (currentSlide.type === `suitable`) {
    slideElement = <SuitableSlide data={currentSlide.data} />;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => {
        if (reason !== `backdropClick`) {
          setOpen(false);
          setCurrentSlide(initialSlide);
        }
      }}
      sx={{
        '> div > div': {
          overflowX: `hidden`,
        },
      }}
    >
      <DialogTitle color="textSecondary">
        Förderanspruch prüfen
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
            setCurrentSlide(initialSlide);
          }}
          sx={{
            position: `absolute`,
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <QuestionHistoryContext.Provider
        value={{
          hasHistory:
            currentSlide.type === `question` && currentSlide.history.length > 0,
          goBack: () => {
            if (
              currentSlide.type === `question` &&
              currentSlide.history.length > 0
            ) {
              setCurrentSlide(currentSlide.history[0]);
            }
          },
        }}
      >
        {slideElement}
      </QuestionHistoryContext.Provider>
    </Dialog>
  );
}

export function CheckerModal({ children }: CheckerModalProps) {
  const [open, setOpen] = React.useState(false);
  const buttonComponent = React.useMemo(
    () =>
      children(() => {
        setOpen(true);
      }),
    [setOpen, children],
  );
  return (
    <>
      {buttonComponent}
      {open && <InnerCheckerModal open={open} setOpen={setOpen} />}
    </>
  );
}
