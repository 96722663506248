import { DeMinimisQuestionData } from '@/components/checker/questions/DeMinimisQuestion';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { CompanyTypes } from '@/components/checker/questions/CompanyQuestion';
import { ItemTypes } from '@/components/checker/questions/ItemTypeQuestion';
import { SendedSuccessfullyPage } from './SendedSuccessfullyPage';

const contactScheme = yup
  .object({
    name: yup.string().required(`Bitte geben Sie Ihren Namen an`),
    email: yup
      .string()
      .trim()
      .email(`Ungültige E-Mail-Adresse`)
      .required(`Bitte geben Sie Ihre E-Mail Adresse an`),
    phoneNumber: yup.string().notRequired(),
    organisation: yup.string().notRequired(),
    acceptedConditions: yup
      .boolean()
      .isTrue(`Bitte stimmen Sie den Bedingungen zu`)
      .required(),
    acceptedForwardConsent: yup.boolean().optional(),
  })
  .defined();

function getAnswersItems(
  contactData: {
    name: string;
    email: string;
    phoneNumber: string;
    organisation: string;
    forwardConsent: boolean;
  },
  data: DeMinimisQuestionData,
): { name: string; value: string }[] {
  const result: { name: string; value: string }[] = [];

  result.push({ name: `Name`, value: contactData.name });
  result.push({ name: `Email`, value: contactData.email });
  result.push({ name: `Telefon`, value: contactData.phoneNumber });
  result.push({ name: `Firma/Organisation`, value: contactData.organisation });
  result.push({
    name: `Unternehmensart`,
    value: CompanyTypes[data.company.type].name,
  });
  result.push({ name: `Gründung vor 31.12.2021`, value: `Ja` });
  if (data.company.type === `kmu`) {
    result.push({ name: `Mitarbeiteranzahl`, value: `Bis zu 249` });
  }
  if (data.company.type === `solo`) {
    result.push({ name: `Alleine, keine Angestellten`, value: `Ja` });
  }
  result.push({
    name: `Fördergegenstand`,
    value: ItemTypes[data.item.type].name,
  });
  result.push({ name: `Beschreibung`, value: data.item.description });
  result.push({
    name: `Geplante Investitionssumme`,
    value:
      data.item.expense !== null ? `${data.item.expense}€` : `Keine Angaben`,
  });
  result.push({ name: `Bereits begonnen`, value: `Nein` });
  result.push({ name: `Bereits begonnen`, value: `Nein` });
  if (data.company.type === `kmu`) {
    result.push({ name: `Beim Berliner FA gemeldet`, value: `Ja` });
    result.push({ name: `Beim Berliner FA gemeldet`, value: `Ja` });
    result.push({ name: `Max. 43 Mio Euro Bilanzsumme`, value: `Ja` });
  }
  if (data.company.type === `solo`) {
    result.push({ name: `In Berlin versteuert`, value: `Ja` });
    result.push({ name: `Hauptberuf`, value: `Ja` });
  }
  result.push({
    name: `Insolvenzverfahren oder wirtschaftliche Schwierigkeiten`,
    value: `Nein`,
  });
  result.push({ name: `Bereits bewilligter Antrag`, value: `Nein` });
  result.push({ name: `Weniger als 183.000 € Förderungen?`, value: `Ja` });
  result.push({
    name: `Einwilligung zur Weitergabe personenbezogener Daten`,
    value: contactData.forwardConsent ? `Vorhanden` : `Nicht vorhanden`,
  });
  return result;
}

type CalculatorSubmission = {
  name: string;
  email: string;
  phone: string;
  organisation: string;
  acceptedForwardConsent: boolean;
  type: string;
  items: { name: string; value: string }[];
  expenseKnown: boolean;
  maxPayment: number;
};

export function SuitableSlide({
  data: inputData,
}: {
  data: DeMinimisQuestionData;
}) {
  const [loading, setLoading] = React.useState(false);
  const [submissionState, setSubmissionState] = React.useState<
    | {
        type: 'success' | 'error';
        message: string;
        contact: { name: string; email: string };
      }
    | undefined
  >(undefined);
  return (
    <DialogContent>
      <DialogContentText>
        {submissionState?.type === `success` && (
          <SendedSuccessfullyPage
            contact={submissionState.contact}
            data={inputData}
          />
        )}
        {submissionState?.type !== `success` && (
          <>
            <Typography variant="body2" mb={2}>
              Ihr Ergebnis wird berechnet.
              <br />
              <br />
              Bitte geben Sie Ihre Kontaktdaten an, damit wir Ihnen das Ergebnis
              per E-Mail zukommen lassen können.
            </Typography>
            <Formik
              initialValues={{
                name: ``,
                email: ``,
                phoneNumber: ``,
                acceptedConditions: false,
                organisation: ``,
                acceptedForwardConsent: false,
              }}
              validateOnChange={false}
              isInitialValid={false}
              validationSchema={contactScheme}
              onSubmit={async (values) => {
                setLoading(true);
                try {
                  const data: CalculatorSubmission = {
                    name: values.name,
                    email: values.email,
                    phone: values.phoneNumber,
                    organisation: values.organisation,
                    acceptedForwardConsent: values.acceptedForwardConsent,
                    type: inputData.item.type,
                    items: getAnswersItems(
                      {
                        name: values.name,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        organisation: values.organisation,
                        forwardConsent: values.acceptedForwardConsent,
                      },
                      inputData,
                    ),
                    expenseKnown: inputData.item.expense !== null,
                    maxPayment: Math.min(
                      Math.floor((inputData.item.expense ?? 0) * 0.5),
                      17000,
                    ),
                  };
                  const response = await fetch(
                    `${process.env.GATSBY_DATA_BROKER_URL}/digital-praemie/calculator`,
                    {
                      method: `POST`,
                      headers: {
                        'Content-Type': `application/json`,
                        Authorization:
                          process.env.GATSBY_DATA_BROKER_AUTH_SECRET ?? ``,
                      },
                      body: JSON.stringify(data),
                    },
                  );
                  setSubmissionState(
                    response.ok
                      ? {
                          type: `success`,
                          message: ``,
                          contact: { name: values.name, email: values.email },
                        }
                      : {
                          type: `error`,
                          message: (await response.json()).message,
                          contact: { name: values.name, email: values.email },
                        },
                  );
                } catch (err) {
                  console.error(`error`, err);
                  setSubmissionState({
                    type: `error`,
                    message: `Ein unerwarteter Fehler ist aufgetreten.`,
                    contact: { name: values.name, email: values.email },
                  });
                }
                setLoading(false);
              }}
            >
              {({ setFieldValue, values, errors, submitForm }) => {
                return (
                  <Box
                    sx={{
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      pt: 1,
                    }}
                  >
                    <Stack spacing={2} maxWidth={700}>
                      <TextField
                        required
                        label="Vor- und Nachname"
                        placeholder={`Gib Sie Ihren Namen ein`}
                        value={values.name}
                        onChange={(evt) =>
                          setFieldValue(`name`, evt.target.value)
                        }
                        error={!!errors.name}
                      />
                      {!!errors.name && (
                        <Typography color="error" variant="body2">
                          {errors.name}
                        </Typography>
                      )}
                      <TextField
                        required
                        label="E-Mail Adresse"
                        placeholder={`beispiel@muster.de`}
                        error={!!errors.email}
                        value={values.email}
                        onChange={(evt) =>
                          setFieldValue(`email`, evt.target.value)
                        }
                      />
                      {!!errors.email && (
                        <Typography color="error" variant="body2">
                          {errors.email}
                        </Typography>
                      )}
                      <TextField
                        label="Tel. /Mobilnummer"
                        placeholder={`+49156 23 452 123`}
                        value={values.phoneNumber}
                        error={!!errors.phoneNumber}
                        onChange={(evt) =>
                          setFieldValue(`phoneNumber`, evt.target.value)
                        }
                      />
                      <TextField
                        label="Firma/Organisation"
                        placeholder={``}
                        value={values.organisation}
                        error={!!errors.organisation}
                        onChange={(evt) =>
                          setFieldValue(`organisation`, evt.target.value)
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            edge="start"
                            sx={{ alignSelf: `flex-start` }}
                            checked={values.acceptedForwardConsent}
                            onChange={(_, checked) =>
                              setFieldValue(`acceptedForwardConsent`, checked)
                            }
                          />
                        }
                        label={
                          <>
                            <Typography variant="body2">
                              Ich stimme zu, dass meine personenbezogenen Daten
                              an die&nbsp;
                              <Link href="https://nuviu.de/" target="_blank">
                                Nuviu Unternehmensberatung GmbH
                              </Link>
                              &nbsp;weitergegeben werden dürfen, damit diese
                              mich bezüglich ihrer Dienstleistungen
                              (Fördermittelberatung) kontaktieren können.
                            </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            edge="start"
                            sx={{ alignSelf: `flex-start` }}
                            checked={values.acceptedConditions}
                            onChange={(_, checked) =>
                              setFieldValue(`acceptedConditions`, checked)
                            }
                          />
                        }
                        label={
                          <>
                            <Typography variant="body2">
                              Ich habe die&nbsp;
                              <Link href="/datenschutz" target="_blank">
                                Datenschutzerklärung
                              </Link>
                              &nbsp;gelesen und verstanden.
                            </Typography>
                            <Typography variant="caption">
                              Hinweis: Sie können Ihre Einwilligung jederzeit
                              für die Zukunft per E-Mail an
                              hello@devconsultberlin.de widerrufen.
                            </Typography>
                          </>
                        }
                      />
                      {!!errors.acceptedConditions && (
                        <Typography color="error" variant="body2">
                          {errors.acceptedConditions}
                        </Typography>
                      )}
                      {loading ? (
                        <LinearProgress />
                      ) : (
                        <Button onClick={() => submitForm()}>Abschicken</Button>
                      )}
                      {submissionState?.type === `error` && (
                        <Alert severity="error">
                          <AlertTitle>
                            Leider ist ein Fehler aufgetreten
                          </AlertTitle>
                          {submissionState.message}
                        </Alert>
                      )}
                    </Stack>
                  </Box>
                );
              }}
            </Formik>
          </>
        )}
      </DialogContentText>
    </DialogContent>
  );
}
