import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { SoloCompanyData } from '@/components/checker/questions/CompanyQuestion';
import { ItemTypeQuestion } from '@/components/checker/questions/ItemTypeQuestion';

export type SoloCompanyBaseQuestionsData = SoloCompanyData & {
  company: {
    noEmployees: boolean;
    foundedInTime: boolean;
  };
};

export function SoloCompanyBaseQuestions({
  data,
  proceedWithUnsuitable,
  proceedWithQuestion,
}: QuestionComponentProps<SoloCompanyData>) {
  return (
    <QuestionsForm
      inputMap={{
        noEmployees: createYesNoInput(
          `Alleine, keine Angestellten?`,
          `noEmployees`,
        ),
        foundedInTime: createYesNoInput(
          `Bestand Ihr Unternehmen bereits vor dem 31.12.2019?`,
          `foundedInTime`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        console.log(`values`, values);
        if (!values.foundedInTime) {
          reasons.push(
            `Nur Firmen, die vor dem 31.12.2019 gegründet wurden können gefördert werden`,
          );
        }
        if (!values.noEmployees) {
          reasons.push(
            `Es können nur Soloselbstständige ohne Angestellte gefördert werden`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(ItemTypeQuestion, {
            company: {
              ...data.company,
              ...values,
            },
          });
        }
      }}
    />
  );
}
