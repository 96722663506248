import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { CompanyEconomyQuestionData } from '@/components/checker/questions/CompanyEconomyQuestion';

export type DeMinimisQuestionData = CompanyEconomyQuestionData & {
  company: {
    fulfillsDeMinimis: boolean;
  };
};

export function DeMinimisQuestion({
  data,
  proceedWithUnsuitable,
  proceedWithSuitable,
}: QuestionComponentProps<CompanyEconomyQuestionData>) {
  return (
    <QuestionsForm
      inputMap={{
        fulfillsDeMinimis: createYesNoInput(
          {
            raw: `Haben Sie innerhalb der letzten drei Steuerjahre bisher weniger als 183.000€ an Förderungen erhalten?`,
            formatted: (
              <>
                Haben Sie innerhalb der letzten drei Steuerjahre bisher{` `}
                <b>weniger</b> als 183.000€ an Förderungen erhalten?
              </>
            ),
          },
          `fulfillsDeMinimis`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (!values.fulfillsDeMinimis) {
          reasons.push(
            `Nur Unternehmen, die in den letzten drei Steuerjahren weniger als 183.000€ an Förderungen erhalten haben, sind förderfähig.`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          (window as any).va(
            `send`,
            `event`,
            `button`,
            `click`,
            `last-calculator-question`,
          );
          proceedWithSuitable({
            ...data,
            company: {
              ...data.company,
              ...values,
            },
          } as DeMinimisQuestionData);
        }
      }}
    />
  );
}
