import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

interface ZusammenfassungStichpunktProps {
  stichpunkt: {
    title: string | null;
    richDescription: any;
    logo: {
      gatsbyImageData: Record<string, unknown> | null;
    } | null;
  };
}

export const ZusammenfassungStichpunkt: FC<ZusammenfassungStichpunktProps> = ({
  stichpunkt,
}) => {
  const image = getImage(stichpunkt.logo!.gatsbyImageData);

  return (
    <Stack
      spacing={2}
      sx={{
        border: `solid .1px`,
        borderColor: `gray`,
        borderRadius: `8px`,
        p: 2,
        bgcolor: `primary.light`,
      }}
    >
      <div>
        {image && <GatsbyImage image={image} alt={`stichpunkt logo`} />}
      </div>
      <Typography component="h4" variant="h5">
        {stichpunkt.title}
      </Typography>
      <Typography color="textSecondary">
        {stichpunkt.richDescription &&
          renderRichText(stichpunkt.richDescription as RenderRichTextData<any>)}
      </Typography>
    </Stack>
  );
};
