import { FC } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { FoerderHoeheStichpunkt } from '@/components/foerderHoehe/FörderHöheStichpunkt';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SideParallax } from '@/components/sideParallax/SideParallx';

const query = graphql`
  query FoerderHoeheData {
    contentfulFoerderHoehe {
      title
      parallaxIcon {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allContentfulFoerderHoeheStichpunkt {
      edges {
        node {
          order
          title
          richDescription {
            raw
          }
        }
      }
    }
  }
`;

export const FoerderHoehe: FC<{ parallax: boolean }> = ({ parallax }) => {
  const data = useStaticQuery<Queries.FoerderHoeheDataQuery>(query);

  const parallaxIcon = getImage(data.contentfulFoerderHoehe!.parallaxIcon);

  const stichpunkte = data.allContentfulFoerderHoeheStichpunkt.edges.map(
    (e) => e.node,
  );

  return (
    <Box py={6}>
      {parallax && (
        <SideParallax position={`right`}>
          {parallaxIcon && (
            <GatsbyImage alt={`parallaxIcon`} image={parallaxIcon} />
          )}
        </SideParallax>
      )}
      <Container>
        <Stack spacing={parallax ? 8 : 3}>
          <Typography id={`Wie?`} variant="h3" textAlign="center">
            {data?.contentfulFoerderHoehe?.title}
          </Typography>
          <Stack
            justifyContent="space-between"
            direction={parallax ? `row` : `column`}
            spacing={parallax ? 3.5 : 2}
          >
            {stichpunkte
              .sort((a, b) => a.order! - b.order!)
              .map((s, i) => (
                <Box key={`foederhoehestichpunkt-${i}`} width={`100%`}>
                  <FoerderHoeheStichpunkt stichpunkt={s} />
                </Box>
              ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
