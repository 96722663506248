import { Formik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  keyframes,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { InfoPopup } from '@/components/checker/components/InfoPopup';
import { useQuestionHistoryContext } from '@/components/checker/CheckerModal';
import React from 'react';

export interface InputDescriptor<T> {
  questionText: string | { raw: string; formatted: React.ReactNode };
  questionInfo?: JSX.Element | string;
  instantSubmitable?: boolean;
  initialValue?: T | undefined;
  validator: yup.AnySchema;
  inputElement: (props: { submit?: () => void }) => JSX.Element;
}

export interface FormProps<M extends Record<string, InputDescriptor<any>>> {
  inputMap: M;
  onSubmit: (values: {
    [k in keyof M]: M[k] extends InputDescriptor<infer V> ? V : never;
  }) => void;
}

const slideRight = keyframes`
  from {
    transform: translateX(30%);
    opacity: 0;
    width: 100%;
  }

  to {
    transform: translateX(0);
    opacity: 1;
    width: 100%;
  }
`;

export function QuestionsForm<M extends Record<string, InputDescriptor<any>>>({
  inputMap,
  onSubmit,
}: FormProps<M>) {
  const theme = useTheme();
  const mobilePadding = useMediaQuery(theme.breakpoints.down(`sm`));
  const questionHistoryContext = useQuestionHistoryContext();
  const isInstantSubmit =
    Object.values(inputMap).length === 1 &&
    Object.values(inputMap)[0].instantSubmitable;

  return (
    <Formik
      initialValues={Object.fromEntries(
        Object.entries(inputMap).map(([k, v]) => [k, v.initialValue]),
      )}
      validateOnChange={false}
      isInitialValid={false}
      validationSchema={yup
        .object(
          Object.fromEntries(
            Object.entries(inputMap).map(([k, v]) => [k, v.validator]),
          ),
        )
        .defined()}
      onSubmit={(values) => {
        onSubmit(values as any);
      }}
    >
      {({ errors, submitForm }) => {
        return (
          <>
            <DialogContent
              sx={{
                animation: `${slideRight} 0.3s`,
                overflowY: `auto`,
              }}
            >
              <Stack spacing={2}>
                {Object.entries(inputMap).map(([key, inputElement]) => {
                  const InputElement = inputElement.inputElement;
                  return (
                    <Box key={key}>
                      <DialogContentText sx={{ mb: 1 }}>
                        <Box display="flex" justifyContent="top">
                          <Typography color="primary">
                            {typeof inputElement.questionText === `string`
                              ? inputElement.questionText
                              : inputElement.questionText.formatted}
                          </Typography>
                          <Box pl={2}>
                            {inputElement.questionInfo && (
                              <InfoPopup content={inputElement.questionInfo} />
                            )}
                          </Box>
                        </Box>
                      </DialogContentText>
                      <Box pl={1}>
                        <InputElement
                          submit={
                            isInstantSubmit
                              ? () => {
                                  setTimeout(submitForm, 10);
                                }
                              : undefined
                          }
                        />
                      </Box>
                      {!!errors[key] && (
                        <DialogContentText color="error">
                          {`${errors[key]}`}
                        </DialogContentText>
                      )}
                      {isInstantSubmit || <Divider />}
                    </Box>
                  );
                })}
              </Stack>
            </DialogContent>
            <DialogActions
              sx={{
                paddingRight: mobilePadding ? `25px` : undefined,
                justifyContent: `space-between`,
              }}
            >
              {questionHistoryContext.hasHistory && (
                <Button onClick={() => questionHistoryContext.goBack()}>
                  Zurück
                </Button>
              )}
              {isInstantSubmit || (
                <Button onClick={() => submitForm()}>Weiter</Button>
              )}
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
