import { graphql, useStaticQuery } from 'gatsby';
export const useSiteMetadata = (): { title: string; description: string } => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMetaData(id: { eq: "67ff8a73-b318-5e1d-9bf4-0f7c9e670248" }) {
        title
        description
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return data.contentfulMetaData;
};
