import { Box, Container, Stack, Typography } from '@mui/material';
import { UnsereHilfeStichpunkt } from '@/components/usereHilfe/UnsereHilfeStichpunkt';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SideParallax } from '@/components/sideParallax/SideParallx';
import { FC } from 'react';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

const query = graphql`
  query UnsereHilfeData {
    contentfulUsereHilfeZusammenfassung(
      id: { eq: "861d6491-d9ee-54d4-a59e-ead63257ae30" }
    ) {
      id
      title
      richDescription {
        raw
      }
      parallaxIcon {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allContentfulUnsereHilfeStichpunkt {
      edges {
        node {
          order
          title
          richDescription {
            raw
          }
          buttonText
          logo {
            gatsbyImageData(
              width: 42
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export const UnsereHilfe: FC<{ parallax: boolean }> = ({ parallax }) => {
  const data = useStaticQuery<Queries.UnsereHilfeDataQuery>(query);

  const parallaxIcon = getImage(
    data.contentfulUsereHilfeZusammenfassung!.parallaxIcon,
  );

  const stichpunkte = data.allContentfulUnsereHilfeStichpunkt.edges.map(
    (e) => e.node,
  );

  return (
    <Box py={6}>
      {parallax && (
        <SideParallax position={`right`}>
          {parallaxIcon && (
            <GatsbyImage alt={`parallaxIcon`} image={parallaxIcon} />
          )}
        </SideParallax>
      )}
      <Container>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h3">
            {data.contentfulUsereHilfeZusammenfassung?.title}
          </Typography>
          <Typography color="text.secondary">
            {data.contentfulUsereHilfeZusammenfassung?.richDescription &&
              renderRichText(
                data.contentfulUsereHilfeZusammenfassung
                  .richDescription as RenderRichTextData<any>,
              )}
          </Typography>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-around`,
              pt: parallax ? 6 : 0,
              flexDirection: parallax ? `row` : `column`,
            }}
          >
            {stichpunkte
              .sort((a, b) => (a as any).order - (b as any).order)
              .map((s, i) => (
                <UnsereHilfeStichpunkt
                  stichpunkt={s}
                  key={`stichpunkt-${i}`}
                  parallax={parallax}
                />
              ))}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
