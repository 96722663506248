import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { SoloCompanyBaseQuestions } from '@/components/checker/questions/SoloCompanyBaseQuestions';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createOptionInput } from '@/components/checker/components/OptionQuestionInput';
import { KMUCompanyBaseQuestions1 } from '@/components/checker/questions/KMUCompanyBaseQuestions';

export const CompanyTypes = {
  kmu: {
    name: `KMU`,
    description: (
      <>
        Klein- und Mittelständische Unternehmen mit einem gewerbesteuerpflichtig
        bei einem Berliner Finanzamt bemeldeten Haupt- oder Betriebssitz.
        <br />
        Dazu zählen unter anderem auch die gemeinnützige UG sowie die
        gemeinnützige GmbH.
      </>
    ),
  },
  solo: {
    name: `Soloselbstständig`,
    description: (
      <>
        Personen, die eine selbstständige Tätigkeit ohne angestellte im
        Hauptberuf ausüben und im Land Berlin versteuern.
        <br />
        Hierzu zählen auch insbesondere Freiberufler.
      </>
    ),
  },
  others: {
    name: `Sonstiges`,
    description: undefined,
  },
};

export type KMUCompanyData = { company: { type: 'kmu' } };
export type SoloCompanyData = { company: { type: 'solo' } };

export function CompanyQuestion({
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<{}>) {
  return (
    <QuestionsForm
      inputMap={{
        companyType: createOptionInput(
          `Was trifft auf Sie zu?`,
          `companyType`,
          Object.entries(CompanyTypes).map(([key, v]) => ({
            key: key as keyof typeof CompanyTypes,
            label: v.name,
            info: v.description,
          })),
        ),
      }}
      onSubmit={(values) => {
        if (values.companyType === `solo`) {
          proceedWithQuestion(SoloCompanyBaseQuestions, {
            company: { type: values.companyType },
          });
        } else if (values.companyType === `kmu`) {
          proceedWithQuestion(KMUCompanyBaseQuestions1, {
            company: { type: values.companyType },
          });
        } else {
          proceedWithUnsuitable([
            `Zum Antrag der Förderung sind ausschließlich klein- und mittelständische Unternehmen sowie Soloselbstständige berechtigt.`,
          ]);
        }
      }}
    />
  );
}
