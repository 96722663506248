import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
export function UnsuitableSlide({
  reasons,
  revert,
}: {
  reasons: string[];
  revert: () => void;
}) {
  return (
    <DialogContent>
      <DialogContentText>
        <Typography component="h2" variant="h3" color="error">
          Nicht förderfähig
        </Typography>
        <Typography variant="body1">
          Leider fallen Sie nicht unter die Förderkriterien. Folgende Punkte
          entsprechen nicht den Richtlinien:
        </Typography>
        <List>
          {reasons.map((reason) => (
            <ListItem key={reason}>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText>{reason}</ListItemText>
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Typography variant="body1">Verklickt?</Typography>
          <Button
            sx={{
              fontWeight: `bold`,
            }}
            variant="text"
            onClick={revert}
          >
            Zurück zur letzten Fragen
          </Button>
        </Box>
      </DialogContentText>
    </DialogContent>
  );
}
