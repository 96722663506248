import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { CheckerModal } from '@/components/checker/CheckerModal';

function scrollToTargetAdjusted(targetElement: string) {
  const element = document.getElementById(targetElement);
  const headerOffset = 160;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: `smooth`,
    });
  }
}

const pages = [`Wer?`, `Wie?`, `Was?`];

function ResponsiveAppBar() {
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: `none`, md: `inline-flex`, alignItems: `center` },
              fontSize: `1.8rem`,
              fontWeight: 700,
              letterSpacing: `.2rem`,
              color: `inherit`,
              textDecoration: `none`,
            }}
          >
            Digitalprämie Berlin
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: `flex`, md: `none` },
              flexGrow: 1,
              fontSize: `1.3rem`,
              fontWeight: 700,
              letterSpacing: `.2rem`,
              color: `inherit`,
              textDecoration: `none`,
            }}
          >
            💰&nbsp;Digitalprämie Berlin
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: `none`, md: `flex` } }}>
            {pages.map((page) => (
              <Button
                variant="text"
                key={page}
                onClick={() => {
                  const element = document.getElementById(page);
                  if (element) {
                    scrollToTargetAdjusted(page);
                  }
                }}
                sx={{ my: 2, color: `white`, display: `block` }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <CheckerModal>
              {(onClick) => (
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    maxWidth: 240,
                    fontSize: { xs: `.7rem`, sm: `default` },
                  }}
                  onClick={onClick}
                >
                  Jetzt Prüfen
                </Button>
              )}
            </CheckerModal>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
