import { Field, FieldProps } from 'formik';
import { InputDescriptor } from '@/components/checker/components/QuestionsForm';
import * as yup from 'yup';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export interface YesNoQuestionInputProps {
  fieldName: string;
  submit?: () => void;
}

export function YesNoQuestionInput({
  fieldName,
  submit,
}: YesNoQuestionInputProps) {
  return (
    <Field
      name={fieldName}
      render={({ meta, form }: FieldProps<boolean | undefined>) => {
        return (
          <RadioGroup name={fieldName} value={meta.value}>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  value={true}
                  control={<Radio checked={meta.value === true} />}
                  onChange={(event, checked) => {
                    if (checked) {
                      form.setFieldValue(fieldName, true);
                      if (submit) {
                        submit();
                      }
                    }
                  }}
                  label="Ja"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Radio checked={meta.value === false} />}
                  onChange={(event, checked) => {
                    if (checked) {
                      form.setFieldValue(fieldName, false);
                      if (submit) {
                        submit();
                      }
                    }
                  }}
                  label="Nein"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        );
      }}
    />
  );
}

export function createYesNoInput(
  questionText: string | { raw: string; formatted: React.ReactNode },
  fieldName: string,
  questionInfo?: string,
): InputDescriptor<boolean> {
  return {
    questionText,
    questionInfo,
    instantSubmitable: true,
    validator: yup.boolean().required(`Bitte wählen Sie Ja oder Nein`),
    inputElement: ({ submit }) => (
      <YesNoQuestionInput submit={submit} fieldName={fieldName} />
    ),
  };
}
