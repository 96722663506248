import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { ProjectStartedQuestionData } from '@/components/checker/questions/ProjectStartedQuestion';
import { SoloCompanyBaseQuestionsData } from '@/components/checker/questions/SoloCompanyBaseQuestions';
import { CompanyEconomyQuestion } from '@/components/checker/questions/CompanyEconomyQuestion';

export type SoloCompanyExtendedQuestionsData = ProjectStartedQuestionData &
  SoloCompanyBaseQuestionsData & {
    company: {
      inBerlin: boolean;
      mainJob: boolean;
    };
  };
export function SoloCompanyExtendedQuestions({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<
  ProjectStartedQuestionData & SoloCompanyBaseQuestionsData
>) {
  return (
    <QuestionsForm
      inputMap={{
        inBerlin: createYesNoInput(
          `Versteuern Sie Ihre Einnahmen in Berlin?`,
          `inBerlin`,
        ),
        mainJob: createYesNoInput(
          `Ist Ihre Soloselbstständigkeit Ihr Hauptberuf?`,
          `mainJob`,
          `Eine hauptberufliche Tätigkeit liegt vor, wenn mindestens 51% Ihrer Einkünfte aus der selbstständigen bzw. freiberuflichen Tätigkeit stammen und mindestens 27.000€ erwirtschaftet werden.`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (!values.inBerlin) {
          reasons.push(
            `Nur Soloselbstständige, die ihre Einnahmen im Land Berlin versteuern, sind förderfähig`,
          );
        }
        if (!values.mainJob) {
          reasons.push(
            `Soloselbstständige sind nur förderfähig, wenn sie diese Tätigkeit als Hauptberuf ausüben`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(CompanyEconomyQuestion, {
            ...data,
            company: {
              ...data.company,
              ...values,
            },
          });
        }
      }}
    />
  );
}
