import { FC } from 'react';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { FoerderBerechtigteStichpunkt } from '@/components/foerderBerechtigte/FoerderBerechtigteStichpunkt';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SideParallax } from '@/components/sideParallax/SideParallx';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { CheckerModal } from '@/components/checker/CheckerModal';

const query = graphql`
  query FoerderBerechtigteData {
    contentfulFoerderBerechtigteZusammenfassung(
      id: { eq: "4a63a797-f68a-5d8d-b003-3bc07ee84dd7" }
    ) {
      title
      richDescription {
        raw
      }
      buttonText
      parallaxIcon {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allContentfulFoerderBerechtigteStichpunkt {
      edges {
        node {
          order
          description
          logo {
            gatsbyImageData(
              width: 42
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export const FoerderBerechtigte: FC<{ parallax: boolean }> = ({ parallax }) => {
  const data = useStaticQuery<Queries.FoerderBerechtigteDataQuery>(query);

  const parallaxIcon = getImage(
    data.contentfulFoerderBerechtigteZusammenfassung!.parallaxIcon,
  );

  const stichpunkte = data.allContentfulFoerderBerechtigteStichpunkt.edges.map(
    (e) => e.node,
  );

  return (
    <Box
      sx={{
        bgcolor: `primary.light`,
        py: 6,
        // height: parallax ? `70vh` : undefined,
      }}
    >
      {parallax && (
        <SideParallax position={`left`}>
          {parallaxIcon && (
            <GatsbyImage alt={`parallaxIcon`} image={parallaxIcon} />
          )}
        </SideParallax>
      )}
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={parallax ? 6 : 2}>
              <Typography id={`Wer?`} variant="h3">
                {data.contentfulFoerderBerechtigteZusammenfassung?.title}
              </Typography>
              <Typography color="text.secondary">
                {data.contentfulFoerderBerechtigteZusammenfassung
                  ?.richDescription &&
                  renderRichText(
                    data.contentfulFoerderBerechtigteZusammenfassung
                      .richDescription as RenderRichTextData<any>,
                  )}
              </Typography>
              <CheckerModal>
                {(onClick) => (
                  <Button
                    onClick={onClick}
                    sx={{
                      width: 115,
                      alignSelf: parallax ? undefined : `center`,
                    }}
                  >
                    Jetzt Prüfen
                  </Button>
                )}
              </CheckerModal>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2}>
              {stichpunkte
                .sort((a, b) => a.order! - b.order!)
                .map((s, i) => (
                  <Grid
                    item
                    key={`stichpunkt-${i}`}
                    xs={6}
                    sx={{
                      display: `flex`,
                      alginItems: `center`,
                      justifyContent: `center`,
                    }}
                  >
                    <FoerderBerechtigteStichpunkt stichpunkt={s} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
