import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { createYesNoInput } from '@/components/checker/components/YesNoQuestionInput';
import { createOptionInput } from '@/components/checker/components/OptionQuestionInput';
import { KMUCompanyData } from '@/components/checker/questions/CompanyQuestion';
import { ItemTypeQuestion } from '@/components/checker/questions/ItemTypeQuestion';

export type KMUCompanyBaseQuestionsData1 = KMUCompanyData & {
  company: {
    amountOfEmployees: 'up-to-249' | 'more-than-249';
  };
};
export type KMUCompanyBaseQuestionsData = KMUCompanyBaseQuestionsData1 & {
  company: {
    foundedInTime: boolean;
  };
};
export function KMUCompanyBaseQuestions1({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<KMUCompanyData>) {
  return (
    <QuestionsForm
      inputMap={{
        amountOfEmployees: createOptionInput(
          `Wieviele Mitarbeiter beschäftigen Sie?`,
          `amountOfEmployees`,
          [
            {
              key: `up-to-249` as 'up-to-249',
              label: `bis zu 249`,
            },
            {
              key: `more-than-249` as 'more-than-249',
              label: `250 oder mehr`,
            },
          ],
          `Hier gilt das Vollzeitäquivalent der wöchentlichen Arbeitszeit der sozialversicherungspflichtigen Beschäftigten zum Stichtag 31.12.2021`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (values.amountOfEmployees === `more-than-249`) {
          reasons.push(
            `Klein- und mittelständische Unternehmen können nur bis zu einer Mitarbeiteranzahl von 249 gefördert werden`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(KMUCompanyBaseQuestions, {
            company: {
              ...data.company,
              ...values,
            },
          });
        }
      }}
    />
  );
}

export function KMUCompanyBaseQuestions({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<KMUCompanyBaseQuestionsData1>) {
  return (
    <QuestionsForm
      inputMap={{
        foundedInTime: createYesNoInput(
          `Bestand Ihr Unternehmen bereits vor dem 31.12.2021?`,
          `foundedInTime`,
        ),
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (!values.foundedInTime) {
          reasons.push(
            `Nur Firmen, die vor dem 31.12.2021 gegründet wurden können gefördert werden`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(ItemTypeQuestion, {
            company: {
              ...data.company,
              ...values,
            },
          });
        }
      }}
    />
  );
}
