import { FC } from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { ZusammenfassungStichpunkt } from '@/components/zusammenfassung/ZusammenfassungStichpunkt';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SideParallax } from '@/components/sideParallax/SideParallx';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

const query = graphql`
  query ZusammenfassungData {
    contentfulZusammenfassung(
      id: { eq: "50aab41b-9c52-5fc3-b706-f2cd976339df" }
    ) {
      title
      richDescription {
        raw
      }
      parallaxIcon {
        gatsbyImageData(
          width: 100
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allContentfulZusammenfassungStichpunkt {
      edges {
        node {
          title
          richDescription {
            raw
          }
          logo {
            gatsbyImageData(
              width: 40
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export const ZusammenFassung: FC<{ parallax: boolean }> = ({ parallax }) => {
  const data = useStaticQuery<Queries.ZusammenfassungDataQuery>(query);

  const parallaxIcon = getImage(data.contentfulZusammenfassung!.parallaxIcon);

  const stichPunkte = data.allContentfulZusammenfassungStichpunkt.edges.map(
    (e) => e.node,
  );
  return (
    <Box pt={6}>
      <SideParallax position={`left`}>
        {parallax && parallaxIcon && (
          <GatsbyImage alt={`parallaxIcon`} image={parallaxIcon} />
        )}
      </SideParallax>
      <Container>
        <Stack spacing={5}>
          <Typography id={`Was?`} variant="h3" textAlign="center">
            {data.contentfulZusammenfassung?.title}
          </Typography>
          <Typography color="text.secondary">
            {data.contentfulZusammenfassung?.richDescription &&
              renderRichText(
                data.contentfulZusammenfassung
                  ?.richDescription as RenderRichTextData<any>,
              )}
          </Typography>
          <Stack
            justifyContent="space-between"
            direction={parallax ? `row` : `column`}
            spacing={2}
          >
            {stichPunkte.map((s, i) => (
              <Box key={`stichpunkt-${i}`} width={`100%`}>
                <ZusammenfassungStichpunkt stichpunkt={s} />
              </Box>
            ))}
          </Stack>
        </Stack>
        <Divider sx={{ mt: 6 }} />
      </Container>
    </Box>
  );
};
