import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

interface Stichpunkt {
  title: string | null;
  order: number | null;
  richDescription: any;
  buttonText: string | null;
  logo: {
    gatsbyImageData: Record<string, unknown> | null;
  } | null;
}

interface UnsereHilfeStichpunktProps {
  stichpunkt: Stichpunkt;
  parallax: boolean;
}

export const UnsereHilfeStichpunkt: FC<UnsereHilfeStichpunktProps> = ({
  stichpunkt,
  parallax,
}) => {
  const image = getImage(stichpunkt.logo!.gatsbyImageData);
  return (
    <Stack
      spacing={parallax ? 2 : 0.5}
      alignItems="center"
      sx={{ px: 2, maxWidth: 350 }}
    >
      <div>
        {image && <GatsbyImage image={image} alt={`stichpunkt logo`} />}
      </div>
      <Typography component="h4" variant="h5" sx={{ textAlign: `center` }}>
        {stichpunkt.title}
      </Typography>
      <Typography color="textSecondary" sx={{ textAlign: `center` }}>
        {stichpunkt.richDescription &&
          renderRichText(stichpunkt.richDescription as RenderRichTextData<any>)}
      </Typography>
    </Stack>
  );
};
