import { FC, PropsWithChildren } from 'react';
import { useSiteMetadata } from '@/siteMetaData';
import { Helmet } from 'react-helmet';

interface SeoProps extends PropsWithChildren {
  title?: string | null;
  description?: string | null;
}

export const SEO: FC<SeoProps> = ({ children }) => {
  const { title: defaultTitle, description: defaultDescription } =
    useSiteMetadata();

  const seo = {
    title: defaultTitle,
    description: defaultDescription,
  };
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:title" content={seo.title} />
      </Helmet>
      {children}
    </>
  );
};
