import { QuestionComponentProps } from '@/components/checker/components/QuestionComponent';
import { QuestionsForm } from '@/components/checker/components/QuestionsForm';
import { ItemTypeQuestionsData } from '@/components/checker/questions/ItemTypeQuestion';
import * as yup from 'yup';
import { Field, FieldProps } from 'formik';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { ProjectStartedQuestion } from '@/components/checker/questions/ProjectStartedQuestion';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

export type ItemExpenseQuestionsData = ItemTypeQuestionsData & {
  item: {
    expense: number | null;
    description: string;
  };
};

function ItemDescriptionInput() {
  return (
    <Field
      name="itemDescription"
      render={({ meta, form }: FieldProps<string>) => {
        return (
          <TextField
            sx={{ marginBottom: `5px` }}
            fullWidth
            multiline
            rows={4}
            size="small"
            value={meta.value}
            onChange={(e) =>
              form.setFieldValue(`itemDescription`, e.target.value)
            }
          />
        );
      }}
    />
  );
}

function ItemExpenseInput() {
  return (
    <Field
      name="itemExpense"
      render={({ meta, form }: FieldProps<number | null | undefined>) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              {meta.value !== null && (
                <TextField
                  disabled={meta.value === null}
                  type="number"
                  size="small"
                  InputProps={{
                    endAdornment: <EuroSymbolIcon fontSize="small" />,
                    inputProps: {
                      style: {
                        textAlign: `right`,
                      },
                      min: 0,
                      max: 1000000,
                      step: 1,
                    },
                  }}
                  value={meta.value ?? ``}
                  onChange={(e) =>
                    form.setFieldValue(`itemExpense`, Number(e.target.value))
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox />}
                checked={meta.value === null}
                onChange={(_, checked) => {
                  form.setFieldValue(`itemExpense`, checked ? null : undefined);
                }}
                label="Ich möchte keine Angaben machen"
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export function ItemExpenseQuestion({
  data,
  proceedWithQuestion,
  proceedWithUnsuitable,
}: QuestionComponentProps<ItemTypeQuestionsData>) {
  return (
    <QuestionsForm
      inputMap={{
        itemDescription: {
          initialValue: ``,
          questionText: `Beschreiben Sie bitte in einigen Sätzen Ihr geplantes Vorhaben (optional)`,
          validator: yup.string().notRequired(),
          inputElement: ItemDescriptionInput,
        },
        itemExpense: {
          initialValue: undefined as number | null | undefined,
          questionText: `Wie hoch ist ihr geplanter Investitionsbetrag?`,
          questionInfo: (
            <>
              Gemeint ist der Nettobetrag an externen Sachausgaben für
              Lieferungen und Leistungen.
            </>
          ),
          validator: yup
            .number()
            .min(1, `Bitte geben Sie einen positiven Betragen an`)
            .max(1000000)
            .integer(`Bitte runden Sie den Betrag auf eine ganze Zahl`)
            .defined(
              `Bitte tragen Sie einen Betrag ein oder wählen sie keine Angabe`,
            )
            .nullable(),
          inputElement: ItemExpenseInput,
        },
      }}
      onSubmit={(values) => {
        const reasons = [] as string[];
        if (values.itemExpense !== null && values.itemExpense < 2000) {
          reasons.push(
            `Eine Förderung ist erst aber einem Betrag von 2000€ möglich`,
          );
        }
        if (reasons.length > 0) {
          proceedWithUnsuitable(reasons);
        } else {
          proceedWithQuestion(ProjectStartedQuestion, {
            ...data,
            item: {
              ...data.item,
              description: values.itemDescription,
              expense: values.itemExpense,
            },
          });
        }
      }}
    />
  );
}
