import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';

export function InfoPopup({ content }: { content: JSX.Element | string }) {
  return (
    <Tooltip title={content} enterTouchDelay={10}>
      <HelpOutlineIcon
        sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
        fontSize="small"
      />
    </Tooltip>
  );
}
